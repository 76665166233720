/* Customize your main colors in :root variables */
:root {
  --main-background-color: #000;
  --card-background-color: #42424287;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("https://physis-eu.s3.eu-central-1.amazonaws.com/prive/prive-mint-bg.jpg");
  background-size: cover;
  font-family: Clonoid, sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9em;
  
}

h2, p{
  color: var(--main-text-color);
  font-size: 1.2em;
}

h3{
font-weight: normal;
}

